import React from 'react'
import PropTypes from 'prop-types'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Steps from './Steps'
import Head from './Head'
import Carousel from './Carousel'
import Footer from './Footer'

import bestImage from '../static/images/best.png'

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'linear-gradient(157.59deg, #6DEEC0 0%, #5DCDAD 100%)',
  },
  bestImageContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    objectFit: 'contain',
  },
  bestImage: {
    width: '100%',
    height: 'auto',
    // [theme.breakpoints.up('sm')]: {
    //   height: '390px',
    // },
  },
  ellipse: {
    width: '220%',
    marginTop: theme.spacing(9) * -1,
    marginLeft: '-60%',
    borderRadius: '50%',
    backgroundColor: '#696EEC',
    paddingBottom: theme.spacing(35),
  },
  carousel: {
    marginTop: -theme.spacing(30),
  },
}))

// eslint-disable-next-line no-unused-vars
function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <Container disableGutters maxWidth={false} style={{ overflow: 'hidden' }}>
      <Grid
        container
        className={classes.container}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Head link={link} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Grid container justifyContent="center" className={classes.ellipse}>
          <Grid
            item
            xs={5}
            sm={3}
            className={classes.bestImageContainer}
          >
            <img
              className={classes.bestImage}
              src={bestImage}
              alt="Best"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.carousel}
      >
        <Grid
          container
          justifyContent="center"
        >
          <Grid item>
            <Carousel />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Steps />
      </Grid>

      <Grid item xs={12}>
        <Footer link={link} />
      </Grid>

    </Container>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
