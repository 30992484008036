import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './ButtonHead'
import Logo from './Logo'
import Image from '../static/images/city3x.png'

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    paddingBottom: theme.spacing(19),
    [theme.breakpoints.up('xs')]: {
      backgroundSize: '120%',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '120%',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '80%',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: '60%',
    },
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(17),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(20),
    },
    [theme.breakpoints.between('440', '600')]: {
      marginTop: theme.spacing(35),
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(55),
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(50),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(60),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(70),
    },
    fontSize: 34,
    fontStyle: 'normal',
    fontWeight: '700',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(5),
  },
}))

function Head({ link }) {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.head}
    >
      <Grid item className={classes.logo}>
        <Logo link={link} />
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        md={5}
        lg={3}
        xl={10}
      >
        <Typography
          variant="h2"
          className={classes.text}
        >
          Check the real-time location of your friends and family
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
      >
        <Grid
          item
          xs={10}
          sm={8}
          md={6}
          lg={3}
          xl={3}
          className={classes.button}
        >
          <Button href={link}>Try For Free</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

Head.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Head
