/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './ButtonFooter'
import Logo from './Logo'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(5),
    width: '180%',
    marginLeft: '-40%',
    borderRadius: '100% 100% 50% 50%',
    minHeight: '40vh',
    background: 'rgba(105, 110, 236, 0.1)',
  },
  text: {
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 'bold',
  },
}))

function Footer({ link }) {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.footer}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Logo link={link} />
      </Grid>
      <Grid
        item
        xs={6}
        sm={3}
      >
        <Button href={link}>Try For Free</Button>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.text}>© 2021 by Azur Apps</Typography>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Footer
