import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
export const MainTheme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: '\'Gilroy\', sans-serif',
    },
  }),
)

export default MainTheme
