import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import stepsItem from '../static/images/stepsItem.svg'
import stepsItemLast from '../static/images/stepsItemLast.svg'

const useStyles = makeStyles((theme) => ({
  steps: {
    marginTop: theme.spacing(8),
    '& > div > div:last-child > div:nth-child(1)': {
      backgroundImage: `url(${stepsItemLast})`,
    },
  },
  stepItem: {
    backgroundImage: `url(${stepsItem})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    height: theme.spacing(15),
  },
  stepItemNumber: {
    color: '#686FF4',
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  stepItemText: {
    paddingTop: theme.spacing(1.5),
    color: '#1B1F2A',
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      fontSize: 25,
    },
    fontWeight: 'bold',
    '& span': {
      padding: theme.spacing(1),
      marginRigh: theme.spacing(1),
      backgroundColor: '#686FF4',
      color: '#fff',
      borderRadius: '9px',
    },
  },
}))

function Steps() {
  const classes = useStyles()

  return (
    <Grid container className={classes.steps}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>1</Typography>
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Connect</span>
              {' '}
              your family and friends
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>2</Typography>
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>See</span>
              {' '}
              their routines and activities
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>3</Typography>
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Get</span>
              {' '}
              notified of their location
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>4</Typography>
          </Grid>
          <Grid item xs={8} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Secure</span>
              {' '}
              people you care about
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Steps
