/* eslint-disable react/prop-types */
import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/core/styles'

const buttonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 9,
    color: '#686FF4',
    '&:hover': {
      backgroundColor: '#696EEC',
      color: '#fff',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: '700',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Button = ({ ...props }) => {
  const classes = buttonStyles()
  const { children } = props

  return (
    <MuiButton
      fullWidth
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      disableElevation
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default Button
